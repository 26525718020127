@use '../../styles/mixins' as mixins;
@use '../../styles/breakpoints' as bp;

html {
	scroll-behavior: smooth;
}

.hero-block {

	position: relative;
	// background: radial-gradient(115.59% 70.41% at -14.19% 54.94%, var(--wp--preset--color--bnpp-green-tint-20) 0%, rgba(245, 251, 248, 0.482) 60.07%, rgba(255, 255, 255, 0.5) 100%);
	
	&>div {
		@include mixins.bnpp-container;
	}

	nav.breadcrumbs {
		margin-bottom: var(--wp--preset--spacing--50);

		ul {
			display: flex;
			align-items: center;
			padding: 0;
			margin: 0;
			list-style: none;
			font-weight: 300;
			flex-wrap: wrap;

			li {

				font-weight: 400;
				font-size: calc(var(--wp--preset--font-size--body) - 2px);

				&:not(:last-child)::after {
					content: ">";
					margin: 0 var(--wp--preset--spacing--30);
					font-family: var(--wp--preset--font-family--bnpp-sans-condensed);
				}

				a {
					color: var(--wp--preset--color--bnpp-black);
					opacity: 0.9;
					font-weight: 300;
					text-decoration: none;

					&:hover {
						text-decoration: underline;
					}
				}

			}
		}
	}
	
	.content-wrapper {
		@include mixins.grid;;

		.featured-image-wrapper {
	
			img {
				width: 100%;
				height: auto;
			}
	
		}

		.heading-label {
			background-color: var(--wp--preset--color--bnpp-green-tint-20);
			width: -moz-fit-content;
			width: fit-content;
			padding: var(--wp--preset--spacing--30);
			border-radius: 50px;
			font-weight: 300;
			font-size: var(--wp--preset--font-size--small);
			display: block;
			margin-bottom: var(--wp--preset--spacing--50);
			line-height: 1;
			min-width: 70px;
			text-align: center;
		}

		h1 {
			// font-size: var(--wp--preset--font-size--heading-l);
			font-weight: 400;
		}

		p {
			font-weight: 300;
			width: min(100%, 500px);
		}
		
	}

	&.dark {
		@include mixins.darkStyles;
	}

	&.green {
		@include mixins.greenStyles;
	}

	&.charcoal {
		@include mixins.charcoalStyles;
	}

	&:is(.dark, .green, .charcoal) {

		* {
			color: var(--wp--preset--color--white);
		}

		&.with-tabs {
			
			.hero-tabs {
				background: var(--wp--preset--color--white);
			}
		}

		nav.breadcrumbs ul li a {
			color: var(--wp--preset--color--white);
		}
	}

	&.with-tabs {
		padding-bottom: var(--wp--preset--spacing--60);

		.hero-tabs {
			position: absolute;
			bottom: 0;
			width: 100%;
			left: 0;
			border-bottom: solid 2px var(--wp--preset--color--bnpp-green-tint-15);
			overflow: auto;

			ul {
				display: flex;
				padding: 0;
				margin: 0;
				list-style: none;
				justify-content: space-evenly;
				gap: 1em;
				width: max(400px, 100%);

				li {
					flex: 1;
					text-align: center;
				
					a {
						display: inline-block;
						position: relative;
						width: 100%;
						text-decoration: none;
						color: var(--wp--preset--color--bnpp-black);
						padding: 1rem 0;
						font-family: var(--wp--preset--font-family--bnpp-sans-condensed);
						font-size: var(--wp--preset--font-size--heading-xs);
						line-height: 1;
				
						&::after {
							content: "";
							position: absolute;
							bottom: 0;
							left: 0;
							height: 5px;
							width: 100%;
							background: var(--wp--preset--color--bnpp-green);
							opacity: 0;
							transition: 200ms;
						}
						
						&:hover::after {
							opacity: 1;
						}
					}
				}

			}

			&.sticky {
				position: fixed;
				top: 0;
				bottom: auto;
				z-index: 99;
				background-color: var(--wp--preset--color--white);
			}
		}

	}

	&.full-size-image {

		.featured-image-wrapper {
			margin: calc(var(--wp--preset--spacing--80) / -1) calc(var(--wp--preset--spacing--80) / -1) calc(var(--wp--preset--spacing--80) / -1) 0;
			
			@media only screen and (max-width: bp.$breakpoint-small) {
				margin: 0;
			}
		}

		&.with-tabs {
			
			padding-bottom: var(--wp--preset--spacing--80);

			.hero-tabs {
				// bottom: auto;
				// top: 100%;
				height: var(--wp--preset--spacing--80);

				ul {
					height: 100%;
    				align-items: center;
				}
			}
		}

	}

}